import { keepPreviousData, useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { pickBy } from 'lodash'
import { useMemo } from 'react'
import { SourceWithEntityName, TWELVE_HOURS_MS } from '@netpurpose/types'
import { valueIsDefined } from '@netpurpose/utils'
import { apiConfig } from '../../config'
import { GeneratedFactApi } from '../../GeneratedApi'
import { getPaginationConfig, useUrlApiConnector } from '../../hooks/useUrlTableApiConnector'
import {
  Portfolio,
  reversePortfolioFieldMap,
  reverseSourceFieldMap,
  transformPortfolio,
  transformSource,
} from '../../models'
import { snakeToCamelKeys } from '../../utils'
import { useApi } from '../useApi'
import { ENTITY_DATA_BY_QUESTION_ID_QUERY_CACHE_KEY } from './useEntities'
import { PAGINATED_HOLDINGS_QUERY_CACHE_KEY } from './useHoldings'

export const usePaginatedPortfolios = ({
  perPage,
  useUrlSync,
}: {
  perPage?: number
  useUrlSync?: boolean
}) => {
  const queryCacheKeyRoot = 'portfolios'

  const { queryString, filterConfig, initialPaginationConfig } = useUrlApiConnector<Portfolio>({
    tableToApiFieldMap: reversePortfolioFieldMap,
    urlKey: queryCacheKeyRoot,
    ...pickBy({ perPage, useUrlSyncEnabled: useUrlSync }, valueIsDefined),
  })

  const { data, ...rest } = useQuery({
    queryKey: [queryCacheKeyRoot, queryString],
    queryFn: () =>
      GeneratedFactApi.portfolios.listPortfolios({
        q: queryString,
      }),
    placeholderData: keepPreviousData,
  })

  const transformedPortfolios = useMemo(
    () =>
      data?.results ? data?.results.map((d) => transformPortfolio(snakeToCamelKeys(d))) : undefined,
    [data?.results],
  )

  const paginationConfig = getPaginationConfig({
    numResults: data?.total,
    paginationConfig: initialPaginationConfig,
  })

  return {
    ...rest,
    data: {
      ...data,
      results: transformedPortfolios,
    },
    filterConfig,
    paginationConfig,
  }
}

export const usePortfolio = ({ portfolioId }: { portfolioId: number | undefined }) => {
  const { data, ...rest } = useQuery({
    queryKey: ['portfolio', portfolioId],
    queryFn: () =>
      portfolioId
        ? GeneratedFactApi.portfolios.getPortfolioWithHoldings({ portfolioId })
        : undefined,
    enabled: !!portfolioId,
  })

  const transformedPortfolio = useMemo(
    () => (data ? transformPortfolio(snakeToCamelKeys(data)) : undefined),
    [data],
  )

  return {
    ...rest,
    data: transformedPortfolio,
  }
}

export const usePortfolioSources = ({
  defaultParams,
  portfolioId,
}: {
  defaultParams?: { [key: string]: string | number | string[] }
  portfolioId: number
}) => {
  const queryCacheKeyRoot = 'portfolioSources'

  const { queryString, filterConfig, initialPaginationConfig } =
    useUrlApiConnector<SourceWithEntityName>({
      tableToApiFieldMap: reverseSourceFieldMap,
      urlKey: queryCacheKeyRoot,
      defaultParams,
    })

  const { data, ...rest } = useQuery({
    queryKey: [queryCacheKeyRoot, queryString, portfolioId],

    queryFn: () =>
      portfolioId
        ? GeneratedFactApi.portfolios.listPortfolioSources({
            portfolioId,
            q: queryString,
          })
        : undefined,

    enabled: !!portfolioId && !!queryString,
    staleTime: TWELVE_HOURS_MS,
    placeholderData: keepPreviousData,
  })

  const transformedSources = useMemo(
    () =>
      data?.results ? data?.results.map((d) => transformSource(snakeToCamelKeys(d))) : undefined,
    [data?.results],
  )

  const paginationConfig = getPaginationConfig({
    numResults: data?.total,
    paginationConfig: initialPaginationConfig,
  })

  return {
    ...rest,
    data: {
      ...data,
      results: transformedSources,
    },
    filterConfig,
    paginationConfig,
  }
}

export const usePortfolioDisclosure = ({
  portfolioId,
  selectedMetricConfigId,
}: {
  portfolioId: Portfolio['id']
  selectedMetricConfigId: string
}) => {
  const { data, isFetching } = useQuery({
    queryKey: ['portfolioDisclosure', portfolioId, selectedMetricConfigId],

    queryFn: () =>
      GeneratedFactApi.portfolios.getDisclosureData({
        portfolioId,
        metricsConfigId: selectedMetricConfigId,
      }),

    staleTime: TWELVE_HOURS_MS,
  })

  return {
    data: snakeToCamelKeys(data),
    isFetching,
  }
}

export type PortfolioDisclosureData = ReturnType<typeof usePortfolioDisclosure>['data']
export type QuestionDisclosure = NonNullable<PortfolioDisclosureData>[number]
export type DisclosureYear = QuestionDisclosure['absoluteYears'][number]

export const useCreateWatchlist = ({ entityIds }: { entityIds: number[] }) => {
  const { api } = useApi()

  const { mutate, isPending } = useMutation<Portfolio, void, { name: string }>({
    mutationFn: ({ name }) => api.portfolio.createWatchlist(name, entityIds),
  })

  return {
    mutate,
    isLoading: isPending,
  }
}

export const useUpdateWatchlist = ({ entityIds }: { entityIds: number[] }) => {
  const { api } = useApi()

  const { mutate: appendToWatchlist, isPending } = useMutation<Portfolio, void, { id: number }>({
    mutationFn: ({ id }) => api.portfolio.appendToWatchlist(id, entityIds),
  })

  return {
    appendToWatchlist,
    isLoading: isPending,
  }
}

export const useWatchlists = () => {
  const { api } = useApi()

  const { data, isFetching } = useQuery({
    queryKey: ['allWatchlists'],
    queryFn: api.portfolio.getAllWatchlists,
  })

  return {
    data,
    isFetching,
  }
}

type UseUpdatePortfolio = {
  portfolioId: number
  onSuccess?: () => void
  onError?: (err: Error) => void
}

export const useUpdatePortfolio = ({ portfolioId, onSuccess, onError }: UseUpdatePortfolio) => {
  const { api } = useApi()
  const queryClient = useQueryClient()

  const { mutate: updatePortfolio, isPending } = useMutation<
    Portfolio,
    Error,
    Partial<Portfolio>,
    unknown
  >({
    mutationFn: (vals) => api.portfolio.update(portfolioId, vals),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['portfolio', portfolioId],
      })
      queryClient.invalidateQueries({
        queryKey: ['portfolios'],
      })
      // Dashboard
      queryClient.invalidateQueries({
        predicate: (query) =>
          ['portfolioImpactData', portfolioId].every((key) => query.queryKey.includes(key)),
      })
      // SDGs
      queryClient.invalidateQueries({
        predicate: (query) =>
          ['portfolioSDGAlignment', portfolioId].every((key) => query.queryKey.includes(key)),
      })
      // Holdings
      queryClient.invalidateQueries({
        predicate: (query) =>
          [PAGINATED_HOLDINGS_QUERY_CACHE_KEY, portfolioId].every((key) =>
            query.queryKey.includes(key),
          ),
      })
      // Data
      queryClient.invalidateQueries({
        predicate: (query) =>
          [ENTITY_DATA_BY_QUESTION_ID_QUERY_CACHE_KEY, portfolioId].every((key) =>
            query.queryKey.includes(key),
          ),
      })
      // Disclosure
      queryClient.invalidateQueries({
        predicate: (query) =>
          ['portfolioDisclosure', portfolioId].every((key) => query.queryKey.includes(key)),
      })
      onSuccess?.()
    },
    onError: (err) => onError?.(err),
  })

  return {
    updatePortfolio,
    isLoading: isPending,
  }
}

type UsePortfolioSDGAlignment = {
  portfolioId: number | undefined
  isWeighted: boolean
}

const getPortfolioSdgOutcomesSummary = ({ portfolioId, isWeighted }: UsePortfolioSDGAlignment) =>
  portfolioId
    ? GeneratedFactApi.portfolios.getPortfolioSdgOutcomesSummary({
        portfolioId,
        isWeighted,
      })
    : undefined

export const usePortfolioSDGAlignmentOutcomes = ({
  portfolioId,
  isWeighted,
  enabled,
}: UsePortfolioSDGAlignment & { enabled: boolean }) => {
  const queryCacheKeyRoot = 'portfolioSDGAlignmentOutcomes'

  const { data, ...rest } = useQuery({
    queryKey: [queryCacheKeyRoot, portfolioId, isWeighted],
    queryFn: () => getPortfolioSdgOutcomesSummary({ portfolioId, isWeighted }),
    enabled: enabled && !!portfolioId,
    staleTime: TWELVE_HOURS_MS,
  })

  const queryClient = useQueryClient()

  // Make sure the initial query has completed successfully.
  if (data) {
    // By prefetching the query with the inverse of the current isWeighted value,
    // we can avoid two separate loading states if the AUM/Holdings tab is switched
    // before the data becomes stale.
    queryClient.prefetchQuery({
      queryKey: [queryCacheKeyRoot, portfolioId, !isWeighted],
      queryFn: () => getPortfolioSdgOutcomesSummary({ portfolioId, isWeighted: !isWeighted }),
      staleTime: apiConfig.defaultStaleTime,
    })
  }

  return {
    ...rest,
    data: snakeToCamelKeys(data),
  }
}

export type PortfolioSDGAlignmentOutcomes = NonNullable<
  ReturnType<typeof usePortfolioSDGAlignmentOutcomes>['data']
>

export const usePortfolioSDGAlignmentRevenueWeighted = ({
  portfolioId,
  enabled,
}: {
  portfolioId: number | undefined
  enabled: boolean
}) => {
  const queryCacheKeyRoot = 'portfolioSDGAlignmentRevenueWeighted'

  const { data, ...rest } = useQuery({
    queryKey: [queryCacheKeyRoot, portfolioId],
    queryFn: () =>
      portfolioId
        ? GeneratedFactApi.portfolios.getPortfolioSdgRevenueSummaryWeighted({ portfolioId })
        : undefined,
    enabled,
    staleTime: TWELVE_HOURS_MS,
  })

  return {
    ...rest,
    data: snakeToCamelKeys(data),
  }
}

export type PortfolioSDGAlignmentRevenueWeighted = NonNullable<
  ReturnType<typeof usePortfolioSDGAlignmentRevenueWeighted>['data']
>

export const usePortfolioSDGAlignmentRevenueUnweighted = ({
  portfolioId,
  enabled,
}: {
  portfolioId: number | undefined
  enabled: boolean
}) => {
  const queryCacheKeyRoot = 'portfolioSDGAlignmentRevenueUnweighted'

  const { data, ...rest } = useQuery({
    queryKey: [queryCacheKeyRoot, portfolioId],
    queryFn: () =>
      portfolioId
        ? GeneratedFactApi.portfolios.getPortfolioSdgRevenueSummaryUnweighted({ portfolioId })
        : undefined,
    enabled,
    staleTime: TWELVE_HOURS_MS,
  })

  return {
    ...rest,
    data: snakeToCamelKeys(data),
  }
}

export type PortfolioSDGAlignmentRevenueUnweighted = NonNullable<
  ReturnType<typeof usePortfolioSDGAlignmentRevenueUnweighted>['data']
>

export const useExportPortfolio = ({
  portfolio,
  selectedMetricConfigId,
  requestPortfolioExport,
}: {
  portfolio: { id: number; name: string }
  selectedMetricConfigId: string
  requestPortfolioExport: (
    resultId: string,
    portfolioId: number,
    portfolioName: string,
    metricConfigId: string,
  ) => void
}) =>
  useMutation({
    mutationFn: () =>
      GeneratedFactApi.portfolios.exportPortfolio({
        requestBody: {
          portfolio_id: portfolio.id,
          export_options: {
            metrics_config_id: selectedMetricConfigId,
            should_send_email: true,
          },
        },
      }),
    onSuccess: ({ result_id }) =>
      requestPortfolioExport(result_id, portfolio.id, portfolio.name, selectedMetricConfigId),
  })

export const useExportPortfolioPAIs = ({
  portfolioId,
  portfolioName,
  requestPortfolioPAIsExport,
}: {
  portfolioId: number
  portfolioName: string
  requestPortfolioPAIsExport: (resultId: string, portfolioId: number, portfolioName: string) => void
}) =>
  useMutation({
    mutationFn: () =>
      GeneratedFactApi.portfolios.exportPortfolioPai({
        requestBody: {
          portfolio_id: portfolioId,
          export_options: { should_send_email: true },
        },
      }),
    onSuccess: ({ result_id }) => requestPortfolioPAIsExport(result_id, portfolioId, portfolioName),
  })

export const useExportPortfolioSDGs = ({
  portfolioId,
  portfolioName,
  requestPortfolioSDGsExport,
}: {
  portfolioId: number
  portfolioName: string
  requestPortfolioSDGsExport: (resultId: string, portfolioId: number, portfolioName: string) => void
}) =>
  useMutation({
    mutationFn: () =>
      GeneratedFactApi.portfolios.exportPortfolioSdg({
        requestBody: {
          portfolio_id: portfolioId,
          export_options: { should_send_email: true },
        },
      }),
    onSuccess: ({ result_id }) => requestPortfolioSDGsExport(result_id, portfolioId, portfolioName),
  })

export const useExportPortfolioSDGRevenue = ({
  portfolioId,
  portfolioName,
  requestPortfolioSDGRevenueExport,
}: {
  portfolioId: number
  portfolioName: string
  requestPortfolioSDGRevenueExport: (
    resultId: string,
    portfolioId: number,
    portfolioName: string,
  ) => void
}) =>
  useMutation({
    mutationFn: () =>
      GeneratedFactApi.portfolios.exportPortfolioSdgRevenue({
        requestBody: {
          portfolio_id: portfolioId,
          export_options: { should_send_email: true },
        },
      }),
    onSuccess: ({ result_id }) =>
      requestPortfolioSDGRevenueExport(result_id, portfolioId, portfolioName),
  })
